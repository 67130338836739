
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import { AuthService, PermissionsHelper } from '@/modules/core/core-auth';
import { UserBaseModel } from '@/modules/core/core-types';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import MaintenanceService from '@/modules/Maintenance/services/MaintenanceService';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn, PwrCardTitle, PwrCard },
  metaInfo: {
    title: 'Super Admin menu'
  }
})
export default class SuperAdminMenu extends PwrVue {
  private isSuperAdmin: boolean = false;

  private enableLogLevelSelector = false;

  get loggingEnabled(): boolean {
    return localStorage.getItem('LOGS_ENABLE') === 'true';
  }

  set loggingEnabled(value: boolean) {
    if (value) {
      localStorage.setItem('LOGS_ENABLE', 'true');
      this.logLevel = LogLevel.CRITICAL;
      this.enableLogLevelSelector = true;
    } else {
      localStorage.removeItem('LOGS_ENABLE');
      this.logLevel = 1000;
      this.enableLogLevelSelector = false;
    }
  }

  get logLevel() {
    const value = localStorage.getItem('LOGS_LEVEL');
    if (value) {
      return Number(value);
    }

    return LogLevel.CRITICAL;
  }

  set logLevel(value: number) {
    localStorage.setItem('LOGS_LEVEL', value.toString());

    LoggingHelper.logLevel = value;
  }

  private async mounted(): Promise<void> {
    this.isInMaintenanceMode = !!(await MaintenanceService.secret());
  }

  private logLevels: { text: string; value: number }[] = [
    {
      text: 'DEBUG',
      value: LogLevel.DEBUG
    },
    {
      text: 'INFO',
      value: LogLevel.INFO
    },
    {
      text: 'ERROR',
      value: LogLevel.ERROR
    },
    {
      text: 'CRITICAL',
      value: LogLevel.CRITICAL
    },
    {
      text: 'IGNORE EVERYTHING',
      value: 1000
    }
  ];

  private async created(): Promise<void> {
    AuthService.onLogin(this.onLogin);
    await AuthService.me();
  }

  private onLogin(user: UserBaseModel | null): void {
    if (user) {
      PermissionsHelper.hasRole('super_administrator', user).then((result: boolean) => {
        this.isSuperAdmin = result;
        this.enableLogLevelSelector = this.loggingEnabled;

        if (!result) {
          this.$router.replace({ 'name': GlobalRoutesNames.E401 });
        }
      });
    }
  }

  private isInMaintenanceMode = false;

  private async onMaintenanceUpButtonClick(): Promise<void> {
    const result = await MaintenanceService.up();

    if (result) {
      this.showSnackbar({
        message: 'Maintenance mode turned off.',
        type: PwrSnackbarTypes.SUCCESS
      });

      await MaintenanceService.deleteCookie();
      this.isInMaintenanceMode = false;
    } else {
      this.showErrorSnackbar('Maintenance mode could not be turned off!');
    }
  }

  private async onMaintenanceDownButtonClick(): Promise<void> {
    const secret = await MaintenanceService.down();

    if (secret) {
      this.showSnackbar({
        message: 'Maintenance mode turned on.',
        type: PwrSnackbarTypes.SUCCESS
      });

      await MaintenanceService.cookie(secret);
      this.isInMaintenanceMode = true;
    } else {
      this.showErrorSnackbar('Maintenance mode could not be turned on!');
    }
  }
}
